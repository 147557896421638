<template>
  <div>
    <component ref="model" :is="getComonent(config.modelName||'Model-1')" backdrop="static" @ready="modelReady">
      <template v-if="linkdata">
        <template v-if="linkdata.type=='Image'">
          <div style="position:relative;">
            <h6>编辑热点链接</h6>
            <div class="" style="position:absolute;top:-5px;right:0;padding:5px;cursor:pointer;" @click="modelHide()">
              <i class="fa fa-times"></i>
            </div>

            <div>
              <div class="form-group">
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">名称</span>
                  </div>
                  <input class="form-control" type="text" v-model.trim="linkdata.title" @change="setHotspot(linkdata)" />
                </div>
                <span class="text-danger"></span>
              </div>
              <div class="form-group">
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">类型</span>
                  </div>
                  <select class="form-control" id="Type" v-model:value="linkdata.linkType">
                    <option v-for="i in types" :value="i.value">{{i.key}}</option>
                  </select>
                </div>
                <span class="text-danger"></span>
              </div>
              <template v-if="linkdata.linkType=='Pano'">
                <button type="button" class="btn btn-outline-primary mb-1" @click="showPanoLibDialog()">{{linkdata.linkItemGUID?`场景:${getItemByGuid(linkdata.linkItemGUID).title}`:'选择目标场景'}}</button>
                <div v-if="linkdata.linkItemGUID">
                  <div class="form-group custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customInheritView" v-model.trim="linkdata.inheritView">
                    <label class="custom-control-label" for="customInheritView">继承视角</label>
                  </div>
                  <template v-if="!linkdata.inheritView">
                    <button type="button" class="btn btn-outline-primary mb-1" @click="SetLinkView()">设置目标场景视角</button>
                    <div>
                      <div>视场:<span>{{linkdata.linkFOV}}</span></div>
                      <div>水平:<span>{{linkdata.linkHLookAt}}</span></div>
                      <div>垂直:<span>{{linkdata.linkVLookAt}}</span></div>
                    </div>
                  </template>
                </div>
              </template>
              <template v-if="linkdata.linkType=='TourGroup'">
                <div class="form-group">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="font-weight:700">目标分组</span>
                    </div>
                    <select class="form-control" id="Type" v-model:value="linkdata.linkGroupGUID">
                      <option v-for="i in form.groups" :value="i.guid">{{i.title}}</option>
                    </select>
                  </div>
                  <span class="text-danger"></span>
                </div>
              </template>
              <div v-if="linkdata.linkType=='File'">
                <button v-if="!linkdata.file" type="button" class="btn btn-outline-primary" @click="showFileLibDialog()">{{linkdata.file?`${linkdata.file.name}`:'选择素材'}}</button>
                <div v-if="linkdata.file&&linkdata.file.isImage">
                  <div class="card-tools float-right">
                    <button type="button" class="btn btn-box-tool" @click="linkdata.file=null" title="删除"><i class="fas fa-trash"></i></button>
                  </div>
                  <a>
                    <div style="height:100px;background-repeat:no-repeat;background-size:contain;background-color:#ccc;background-position:center" :style="{'background-image':'url(' + linkdata.file.url + ')'}"></div>
                  </a>
                </div>
                <div v-else-if="linkdata.file&&linkdata.file.isVideo">
                  {{linkdata.file.name}}
                  <div class="card-tools float-right">
                    <button type="button" class="btn btn-box-tool" @click="linkdata.file=null" title="删除"><i class="fas fa-trash"></i></button>
                  </div>
                  <video style="width:100%;" :src="linkdata.file.url" :poster="((linkdata.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')" controls />
                </div>
                <div v-else-if="linkdata.file&&linkdata.file.isAudio">
                  <button type="button" class="btn btn-outline-primary" @click="showFileLibDialog()">{{linkdata.file?`${linkdata.file.name}`:'选择素材'}}</button>
                </div>
                <div v-else-if="linkdata.file&&linkdata.file.type=='Coordinate'">
                  <button type="button" class="btn btn-outline-primary" @click="showFileLibDialog()">{{linkdata.file?`${linkdata.file.name}`:'选择素材'}}</button>
                  <div v-if="$refs.model.show" style="width:400px">
                    <MapView :geojsonUrl="linkdata.file.url" :dragable="true" height="200px"></MapView>
                  </div>
                </div>
                <div v-else-if="linkdata.file">
                  <button type="button" class="btn btn-outline-primary" @click="showFileLibDialog()">{{linkdata.file?`${linkdata.file.name}`:'选择素材'}}</button>
                </div>
              </div>
              <div v-if="linkdata.linkType!='Pano'&&linkdata.linkType!='TourGroup'">
                <label>内容</label>
                <PellEditor v-model.trim="linkdata.description" />
              </div>
              <template v-if="linkdata.linkType=='Link'">
                <label>链接</label>
                <input v-if="$inRole('管理员')||$inRole('VIP')||form.isPro" class="form-control" type="url" v-model.trim="linkdata.url" placeholder="请输入 Url 地址" />
                <input v-else class="form-control" value="需要开通 Pro 功能" disabled />
              </template>
            </div>
            <div style="display:flex;justify-content:flex-end;padding: 10px 0 0;">
              <button class="btn btn-danger btn-sm mr-2" @click="showDeleteHotspot()">删除</button>
              <button type="button" class="btn btn-primary btn-sm" @click="modelHide()">确定</button>
            </div>
          </div>
        </template>
        <template v-if="linkdata.type=='Video'">
          <div style="position:relative;">
            <h6>编辑视频</h6>
            <div class="" style="position:absolute;top:-5px;right:0;padding:5px;cursor:pointer;" @click="modelHide()">
              <i class="fa fa-times"></i>
            </div>

            <div style="display:flex;justify-content:flex-end;padding: 10px 0 0;">
              <button class="btn btn-danger btn-sm mr-2" @click="showDeleteHotspot()">删除</button>
              <button type="button" class="btn btn-primary btn-sm" @click="modelHide()">确定</button>
            </div>
          </div>

        </template>
      </template>
    </component>
    <SimpleModal ref="PanoLib" :zIndex="1040">
      <div style="position:relative;min-width:200px">
        <h5 class="modal-title">选择目标场景</h5>
        <div class="form-group">
          <div v-if="groups.length>1" class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text" style="font-weight:700">分组</span>
            </div>
            <select class="form-control" id="Type" v-model:value="groupGuid">
              <option v-for="i in groups" :value="i.guid">{{i.title}}</option>
            </select>
          </div>
          <span class="text-danger"></span>
        </div>
        <div class="row scrollbar-invisible" style="overflow-y:auto;max-height: 80vh;">
          <div class="row m-0" style="width:100%">
            <template v-for="(i,index) in groupItems">
              <div class="col-12 col-sm-6" v-if="i.guid!=editItemGuid">
                <div class="small-box bg-gray panolibItem" :class="{active:i.guid==linkdata.linkItemGUID}" style="height: 150px;background-color:#ccc;background-position:center;background-size:cover" v-bind:style="makeUrlStyle((((i.linkItem||{}).firstImage||{}).url||'').replace('/0/0/0/0/', '/200/0/0/0/'))" @click="hotspotPanoLibSelected(i)">
                  <div class="inner">
                    <h6 class="text-truncate text-shadow">{{i.title}}</h6>
                    <!--<h5>{{i.linkItem.user.nickName}}</h5>-->
                  </div>

                  <!--<div class="small-box-footer">
                      <span class="mx-1" title="观看"><i class="fas fa-eye"></i> {{i.viewCount}}</span>
                      <span class="mx-1" title="点赞"><i class="fas fa-thumbs-up"></i> {{i.praiseCount}}</span>
                      <span class="mx-1"><i class="fas fa-map-marker-alt" title="地图坐标" v-if="i.gpsLat!=0 || i.gpsLng!=0"></i></span>
                      <span class="mx-1">{{i.status}}</span>
                      <span class="mx-1" v-if="i.private">私有</span>
                      <span class="mx-1" v-if="i.fine">精</span>
                      <span class="mx-1" v-if="i.featured"><i class="fas fa-star"></i></span>
                      <span class="mx-1">{{i.checkNote}}</span>
                  </div>-->
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="text-shadow" style="position:absolute;top:0;right:0;padding:5px;cursor:pointer;" @click="$refs.PanoLib.show=false">
          <i class="fa fa-times"></i>
        </div>
      </div>
    </SimpleModal>
    <SimpleModal ref="deleteHotspot" :zIndex="1040">
      <div>
        <h5>删除热点</h5>
      </div>
      确定删除此热点？
      <div style="display:flex;justify-content:flex-end;">
        <button type="button" class="btn btn-primary btn-sm mr-2" @click="$refs.deleteHotspot.show=false">取消</button>
        <button class="btn btn-danger btn-sm" @click="deleteHotspot(linkdata)">删除</button>
      </div>
    </SimpleModal>
    <HotspotIconLib ref="HotspotIconLib" :libUrl="urls.hotspotIcons()" :params="params" :onSelected="HotspotIconLibSelected"></HotspotIconLib>
    <FileLib ref="FileLib" :params="params" :libUrl="urls.fileLib()" :panosUploadUrl="urls.panosUploadUrl()" :filesUploadUrl="urls.filesUploadUrl()" :tagsUrl="urls.privateTags()" :fileUrl="urls.fileUrl()" :panoUrl="urls.panoUrl()" @submit="FileLibSelected"></FileLib>
    <FileLib ref="VideoLib" withType="Video" :params="params" :libUrl="urls.fileLib()" :panosUploadUrl="urls.panosUploadUrl()" :filesUploadUrl="urls.filesUploadUrl()" :tagsUrl="urls.privateTags()" :fileUrl="urls.fileUrl()" :panoUrl="urls.panoUrl()" @submit="VideoLibSelected"></FileLib>
  </div>
</template>
<script>
  import { uuid } from 'vue-uuid'
  import HotspotIconView from '../../HotspotIconView'
  import HotspotIconLib from '../../HotspotIconLib'
  import PellEditor from '../../PellEditor'
  import FileLib from '../../FileLib'
  import MapView from '../../Map/MapView'
  import SimpleModal from '../../SimpleModal'
  export default {
    components: {
      HotspotIconView,
      HotspotIconLib,
      PellEditor,
      FileLib,
      MapView,
      SimpleModal,
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        modelShow: null,
        modelHide: null,
        showRange: null,
        groupGuid: null,
        types: [
          {
            key: '场景',
            value: 'Pano',
          },
          {
            key: '分组/博文/相册',
            value: 'TourGroup',
          },
          {
            key: '其他素材',
            value: 'File',
          },
          {
            key: '文字',
            value: 'Text',
          },
          {
            key: '链接',
            value: 'Link',
          },
        ],
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      urls() {
        return this.publicData.urls
      },
      params() {
        return this.publicData.params
      },
      groupItems() {
        var g = []
        for (var i in this.form.items) {
          if (this.form.items[i].groupGUID == this.groupGuid) {
            g.push(this.form.items[i])
          }
        }
        return g
      },
      groups() {
        var g = []
        for (var i in this.form.groups) {
          if (this.form.groups[i].type == 'Pano') {
            g.push(this.form.groups[i])
          }
        }
        return g
      },
      hasGroup() {
        for (var i in this.groups) {
          if (this.groups[i].title) {
            return true
          }
        }
        return false
      },
      linkdata() {
        for (var i in this.pano.hotspots) {
          if (this.pano.hotspots[i].guid == this.editHotspotGuid) {
            return this.pano.hotspots[i]
          }
        }
        return {}
      },
      editHotspotGuid() {
        return this.publicData.editHotspotGuid || null
      },
      hotspots() {
        if (!this.pano.hotspots) {
          return []
        }
        return this.pano.hotspots.filter((v) => v.type != 'Block')
      },
      editItemGuid() {
        return this.publicData.editItemGuid
      },
      editItemIndex() {
        for (var i in this.form.items) {
          if (this.form.items[i].guid == this.editItemGuid) {
            return i
          }
        }
        return null
      },
      pano() {
        return this.publicData.pano || { hotspots: [] }
      },
      form() {
        return this.publicData.form || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          setView: {
            target: 'Data',
            name: 'updatePano',
          },
          linkView: {
            name: 'renewSence',
            arg: 'linkView'
          },
          tweenView: {
            target: 'FullPano',
            name: 'tweenView'
          },
        },
        funcs: {
          show: this.modelShow,
          panoLibShow: this.showPanoLibDialog,
          deleteHotspotShow: this.showDeleteHotspot,
          hotspotIconLibShow: this.showHotspotIconLibDialog,
          fileLibShow: this.showFileLibDialog,
          addNewHotspot: this.addNewHotspot,
          addNewVideo: this.addNewVideo,
        }
      })
      //if (this.editHotspotGuid) {
      //  var h = this.pano.hotspots[this.getHotspotIndex(this.editHotspotGuid)]
      //  this.getHotspot(h)
      //}
    },
    destroyed() {
    },
    methods: {
      getGroupItems(groupGuid) {
        return this.getFunc({ target: 'Data', name: 'getGroupItems' })(groupGuid) || []
      },
      modelReady({ funcs }) {
        this.modelShow = funcs.show
        this.modelHide = funcs.hide
        if (this.publicData.linkView) {
          this.modelShow()
          this.getFunc('changePublicData')({
            linkView: false
          })
        }
      },
      getHotspot(h) {
        var view = {
          hlookat: h.ath,
          vlookat: h.atv,
        }
        this.$emit('tweenView', view)
      },
      setHotspot(hotspot) {
        this.getFunc({ target: 'FullPano', name: 'setHotspot' })(hotspot)
      },
      showHotspotLinkDialog() {
        this.modelShow()
      },
      showPanoLibDialog() {
        if (this.linkdata.linkItemGUID) {
          this.groupGuid = this.getItemByGuid(this.linkdata.linkItemGUID).groupGUID
        } else if (this.groups[0]) {
          this.groupGuid = this.groups[0].guid
        }
        this.$refs.PanoLib.show = true;
      },
      hotspotPanoLibSelected(select) {
        if (select.guid == this.form.items[this.editItemIndex].guid) {
          this.$message({
            message: '您不能选择当前场景',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        if (!this.linkdata) {
          this.$message({
            message: '程序错误',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        this.linkdata.linkItemGUID = select.guid;
        if (this.linkdata.title == '' || this.linkdata.title == null) {
          this.linkdata.title = select.title
          this.setHotspot(this.linkdata)
        }
        console.log(select)
        this.$refs.PanoLib.show = false;
      },
      showFileLibDialog() {
        this.$refs.FileLib.showDialog();
      },
      FileLibSelected(select) {
        if (select.guid == this.panoId) {
          this.$message({
            message: '您不能选择自身全景',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        if (!this.linkdata) {
          this.$message({
            message: '程序错误',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        this.linkdata.file = select;
        if (this.linkdata.title == '' || this.linkdata.title == null) {
          this.linkdata.title = select.name
          this.setHotspot(this.linkdata)
        }
      },
      VideoLibSelected(select) {
        if (!this.linkdata) {
          this.$message({
            message: '程序错误',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          return;
        }
        this.linkdata.file = select;
        this.setVideo(this.linkdata)
      },
      showDeleteHotspot() {
        this.$refs.deleteHotspot.show = true
      },
      deleteHotspot(hotspot) {
        var index = -1
        for (var i in this.pano.hotspots) {
          if (this.pano.hotspots[i].guid == hotspot.guid) {
            index = i;
          }
        }
        if (index < 0) {
          return;
        }
        this.getFunc({ target: 'FullPano', name: 'removeHotspot' })(hotspot)
        this.pano.hotspots.splice(index, 1);

        this.$refs.deleteHotspot.show = false
        this.modelHide()
      },
      SetLinkView() {
        console.log('SetLinkView', this.linkdata.linkItemGUID, this.getItemByGuid(this.linkdata.linkItemGUID))
        var item = { ...this.getItemByGuid(this.linkdata.linkItemGUID).linkItem }
        item.fov = this.linkdata.linkFOV
        item.hLookAt = this.linkdata.linkHLookAt
        item.vLookAt = this.linkdata.linkVLookAt
        var v = this.getFunc({ target: 'FullPano', name: 'getView' })()
        this.modelHide()
        this.getFunc('changePublicData')({
          editHotspotGuid: this.editHotspotGuid, lastView: {
            fov: v.fov,
            vlookat: v.vlookat,
            hlookat: v.hlookat
          }
        })
        this.$emit('setView', item)
        this.$emit('linkView')
      },
      showHotspotIconLibDialog() {
        console.log('showHotspotIconLibDialog')
        this.$refs.HotspotIconLib.showDialog();
      },
      HotspotIconLibSelected(select) {
        var index = this.getHotspotIndex(this.editHotspotGuid)
        this.pano.hotspots[index].icon = select;
        this.setHotspot(this.pano.hotspots[index])
      },
      getHotspotIndex(guid) {
        for (var i in this.pano.hotspots) {
          if (this.pano.hotspots[i].guid == guid) {
            return i
          }
        }
        return null
      },
      addNewHotspot() {
        this.$refs.HotspotIconLib.showDialog((select) => {
          var hotspot = this.newHotspot()
          hotspot.icon = select
          this.setHotspot(hotspot)
          this.pano.hotspots.push(hotspot)
          this.getFunc('changePublicData')({
            editHotspotGuid: hotspot.guid
          })
          this.showHotspotLinkDialog()
        });
      },
      addNewVideo() {
        this.$refs.VideoLib.showDialog((select) => {
          var hotspot = this.newVideo()
          hotspot.file = select
          hotspot.title = select.name
          this.setHotspot(hotspot)
          this.pano.hotspots.push(hotspot)
          this.getFunc('changePublicData')({
            editHotspotGuid: hotspot.guid
          })
          //  this.showHotspotLinkDialog()
        });
      },
      newHotspot(setting) {
        var guid = uuid.v1();
        var view = this.getFunc({ target: 'FullPano', name: 'getView' })()
        return {
          addTime: '',
          ath: view.hlookat || 0,
          atv: view.vlookat || 0,
          description: "",
          distorted: false,
          file: null,
          gpsLat: 0,
          gpsLng: 0,
          guid: guid,
          icon: {
            guid: null,
            width: 0,
            height: 0,
            fps: 0,
            isAnimation: false,
            edge: 'top',
            imageUrl: '%SWFPATH%/skin/vtourskin_hotspot.png',
            //enableText: true,
            textOffsetX: 0,
            textOffsetY: 0,
            textEdge: 'bottom'
          },
          linkFOV: 120,
          linkHLookAt: 0,
          linkItemGUID: null,
          linkType: 'Pano',
          linkVLookAt: 0,
          points: Array[0],
          rotate: 0,
          scale: 0.5,
          rz: 0,
          rx: 0,
          ry: 0,
          loop: false,
          inheritView: false,
          showTitle: true,
          sortID: 2,
          title: "",
          type: 'Image',
          url: null,
          zoom: false,
          ...setting
        }
      },
      newVideo(setting) {
        var guid = uuid.v1();
        var view = this.getFunc({ target: 'FullPano', name: 'getView' })()
        return {
          addTime: '',
          ath: view.hlookat || 0,
          atv: view.vlookat || 0,
          description: "",
          distorted: true,
          file: null,
          guid: guid,
          icon: null,
          points: Array[0],
          rotate: 0,
          scale: 0.5,
          rz: 0,
          rx: 0,
          ry: 0,
          chromakeyColor: null,
          threshold: 0.01,
          smoothing: 0.01,
          loop: true,
          sortID: 2,
          title: "",
          type: 'Video',
          url: null,
          zoom: false,
          ...setting
        }
      },
      getItemByGuid(guid) {
        for (var i in this.form.items) {
          var item = this.form.items[i]
          if (item.guid == guid) {
            return item
          }
        }
        return {}
      },
      getGroupByGuid(guid) {
        for (var i in this.form.groups) {
          var group = this.form.groups[i]
          if (group.guid == guid) {
            return group
          }
        }
        return {}
      },
      makeUrlStyle(url) {
        return {
          'background-image': "url('" + url + "')"
        }
      },
      numberMath(val, step, max, min) {
        val = Number(val)
        step = Number(step)
        val += step
        if (max && val > max) {
          val = max;
        }
        if (min && val < min) {
          val = min;
        }
        return Number(val);
      },
    },
  }
</script>
<style scoped>
  .HotspotList {
    position: absolute;
    top: 0;
    left: 0;
    right: 2.5rem;
    overflow: auto;
    white-space: nowrap;
    font-size: 14px;
    margin: 0;
    opacity: 0.75;
    pointer-events: none;
  }

  .hotspotItem {
    display: inline-block;
    margin: 5px;
    /*width: 4.5em;*/
    vertical-align: top;
  }

    .hotspotItem.active {
      margin: 4px;
      border: 1px solid #fff;
      box-shadow: #000 0 0 2px 0;
    }

    .hotspotItem .badge {
      position: absolute;
      right: 2px;
    }

  .PanoHotspotEdit {
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none !important;
    margin: 5px;
    opacity: 0.75;
  }

    .PanoHotspotEdit > * {
      pointer-events: auto;
      margin: 5px 0;
    }

  .rangeBar {
    background-color: #fff5;
    padding: 5px;
    margin: 0px 0 -5px;
    text-align: center;
    border-radius: 0.2rem;
  }

  .GroupItem {
    display: inline-block;
    width: auto;
    margin: 2px;
    pointer-events: auto;
    vertical-align: top;
    position: relative;
  }

  .group {
    border-bottom: 2px solid #0000;
    /*background-color: #0008;*/
    padding: 2px 4px;
  }

    .group.active {
      border-bottom: 2px solid #ffb400;
      /*background-color: #00f8*/
    }

  .arrowbtn {
    background-color: transparent;
    border: 0;
    border-radius: 4px;
    color: #fff;
    outline: 0;
  }

  .hotspotItem {
    display: inline-block;
    margin: 5px;
    width: 4.1em;
    vertical-align: top;
  }

    .hotspotItem.active {
      margin: 4px;
      border: 1px solid #fff;
      box-shadow: #000 0 0 2px 0;
    }

    .hotspotItem .badge {
      position: absolute;
      right: 2px;
    }

  .panolibItem.active {
    border: solid 4px orange;
  }
</style>
